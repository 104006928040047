import { combineReducers } from "redux";
import {
  enhanceReducer,
  enhanceDefaultState,
} from "redux-async-actions-factory";
import { connectRouter } from "connected-react-router";
import { storeName, asyncActionsNames } from "../asyncConfig";
import asyncDataSuccessReducers from "./asyncDataSuccessReducers";

const enhanceDefaultStateData = (requestActionTitles = asyncActionsNames) =>
  requestActionTitles.reduce(
    (reducedObject, actionTitle) => ({
      ...reducedObject,
      requestData: {
        ...reducedObject.requestData,
        [actionTitle]: [],
      },
    }),
    { requestData: {} }
  );

const defaultState = {
  ...enhanceDefaultState(asyncActionsNames),
  ...enhanceDefaultStateData(),
};

const asyncReducers = (state = defaultState, action) =>
  enhanceReducer(
    storeName,
    state,
    action,
    defaultState,
    asyncDataSuccessReducers
  );
// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    socialLab: asyncReducers,
  });
