import * as bizSdk from "facebook-nodejs-business-sdk";

class FBApi {
  static accessToken: string;
  static api: any;
  static sdk: any = bizSdk;

  static setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  static requestBuilder({
    baseUrl,
    path,
    query
  }: RequestBuilderOptions): Request {
    const queryString = Object.keys(query)
      .map(queryKey => [queryKey, [query[queryKey]].flat(2).join(",")])
      .reduce(
        (queryString, queryItem) =>
          `${queryString}&${queryItem[0]}=${queryItem[1]}`,
        ""
      );

    return new Request(`${baseUrl}/${path}?${queryString}`);
  }

  getApi() {
    if (FBApi.accessToken) {
      if (!FBApi.api) {
        FBApi.api = FBApi.sdk.FacebookAdsApi.init(FBApi.accessToken);
      }
      return FBApi.api;
    }

    return false;
  }

  /**
   * Similar to the biz sdk user get add accounts method, but returns a request
   * object sutiable for passing to fetch or a node backend for request etc
   */
  getUserAddAccounts(userId: number) {
    return (fields: string[]) => {
      const request: RequestBuilderOptions = {
        method: "GET",
        baseUrl: FBApi.sdk.FacebookAdsApi.GRAPH,
        path: [FBApi.sdk.FacebookAdsApi.VERSION, userId, "adaccounts"].join(
          "/"
        ),
        query: {
          access_token: FBApi.accessToken,
          fields
        }
      };
      const call = function() {
        return fetch(FBApi.requestBuilder(request));
      };
      call.request = request;
      return call;
    };
  }
}

export { FBApi };

type RequestBuilderOptions = {
  baseUrl: string;
  method: "GET" | "POST";
  path: string;
  query: {
    [key: string]: string | string[];
  };
};
