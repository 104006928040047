import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../../services/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {auth => {
      return (
        <Route
          {...rest}
          render={props => {
            return auth.isAuthenticated() === true ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }}
        />
      );
    }}
  </AuthContext.Consumer>
);

export default PrivateRoute;
