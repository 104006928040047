import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import Loadable from "react-loadable";

import "./App.scss";
import configureStore, { history } from "./store";
import PrivateRoute from "./containers/PrivateRoute";
import loading from "./components/Loading";
import { auth } from "./services/auth";

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

const CallBack = Loadable({
  loader: () => import("./containers/Callback"),
  loading
});

const Login = Loadable({
  loader: () => import("./containers/Login"),
  loading
});

const Logout = Loadable({
  loader: () => import("./containers/Logout"),
  loading
});

const FBTokenLoader = Loadable({
  loader: () => auth.getFacebookToken(),
  loading,
  render(loaded) {
    return (
      <Switch>
        <PrivateRoute path="/" component={DefaultLayout} />
        <PrivateRoute path="/populate-ads" component={DefaultLayout} />
      </Switch>
    );
  }
});

class App extends Component {
  render() {
    return (
      <Provider store={configureStore()}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/login" name="Login" component={Login} />
            <Route exact path="/logout" name="Logout" component={Logout} />
            <Route path="/callback" component={CallBack} />
            <PrivateRoute path="/" component={FBTokenLoader} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
