import auth0 from "auth0-js";
import React, { createContext } from "react";

import { FBApi } from "../fb-api";

export class Auth {
  auth0: auth0;
  constructor() {

    const redirectUrl = window.location.hostname.includes('thesociallab') ?
      `${process.env.REACT_APP_AUTH0_REDIRECT_NEW_URI}`:
      `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`;

    this.auth0 = new auth0.WebAuth({
      responseType: "id_token token",
      scope: "openid profile email",
      domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      clientID: `${process.env.REACT_APP_AUTH0_CLIENTID}`,
      redirectUri: `${redirectUrl}`
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setSession = this.setSession.bind(this);
    this.getDataFromStorage = this.getDataFromStorage.bind(this);
  }

  getDataFromStorage() {
    try {
      const authData = JSON.parse(window.localStorage.getItem("auth") || '');
      if (authData === null) {
        throw new Error("No Auth Data in Session");
      }
      return authData;
    } catch (e) {
      return {};
    }
  }

  get profile() {
    return this.getDataFromStorage().profile;
  }
  get accessToken() {
    return this.getDataFromStorage().accessToken;
  }
  get idToken() {
    return this.getDataFromStorage().idToken;
  }
  get expiresAt() {
    return this.getDataFromStorage().expiresAt;
  }

  getProfile() {
    return this.profile;
  }

  handleAuthentication(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }

  async handleTiktokAuthCode(authCode: string | string[] | null): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getTikTokToken(authCode).then(
        async res => {
          const body = await res.json();
          if (body.access_token) {
            this.setTikTokSession(body);
            void resolve();
          } else {
            reject()
          }

        }
      ).catch(err => {
        console.warn(err)
      })
    });
  }

  async handlePinterestAuthCode(authCode: string | string[] | null): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getPinterestToken(authCode).then(
        async res => {
          const body = JSON.parse((await res.json()))


          if (body.access_token) {
            this.setPinterestSession(body)
            void resolve();
          } else {
            reject()
          }

        }
      ).catch(err => {
        console.warn(err)
      })
    });
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  login() {
    this.auth0.authorize();
  }

  logout() {
    window.localStorage.removeItem("auth");
    this.auth0.logout({ returnTo: window.location.origin });
  }

  setSession(authResult) {
    window.localStorage.setItem(
      "auth",
      JSON.stringify({
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        profile: authResult.idTokenPayload,
        // set the time that the id token will expire a
        expiresAt: authResult.expiresIn * 1000 + new Date().getTime()
      })
    );
  }

  getFacebookToken() {
    return fetch(
      `${process.env.REACT_APP_MICROSERVICE_BACKEND}fb/access-token`,
      {
        headers: {
          Authorization: `Bearer ${this.idToken}`
        }
      }
    )
      .then(res => res.json())
      .then(({ access_token }) => {
        FBApi.setAccessToken(access_token);
        return access_token;
      });
  }

  async getTikTokToken(authCode: string | string[] | null): Promise<any> {
    return fetch(
      `https://7y8n1snkjh.execute-api.us-west-2.amazonaws.com/prod/tiktok/access-token?authCode=${authCode}`,
      {
        headers: {
          Authorization: `Bearer ${this.idToken}`
        }
      }
    )
  }

  async getPinterestToken(authCode: string | string[] | null): Promise<any> {
    return fetch(
      `https://7y8n1snkjh.execute-api.us-west-2.amazonaws.com/prod/pinterest/token?authCode=${authCode}`,
      {
        headers: {
          Authorization: `Bearer ${this.idToken}`
        }
      }
    )
  }

  async getTikTokReport({accessToken, startDate, endDate, advertiserId}: Record<string, string>): Promise<any> {
    return fetch(
      `${process.env.REACT_APP_TIKTOK_ACCESS_TOKEN}tiktok/basic-reports?access_token=${accessToken}&start_date=${startDate}&end_date=${endDate}&advertiser_id=${advertiserId}`,
      {
        headers: {
          Authorization: `Bearer ${this.idToken}`
        }
      }
    )
  }

  setTikTokSession(authResult) {
    window.localStorage.setItem(
      "tiktok_auth",
      JSON.stringify({
        accessToken: authResult.access_token,
        advertisers: authResult.advertiser_ids,
        expiresAt: authResult.expiresIn * 1000 + new Date().getTime()
      })
    );
  }

  setPinterestSession(authResult) {
    window.localStorage.setItem(
      "pinterest_auth",
      JSON.stringify({
        accessToken: authResult.access_token,
        refresh_token: authResult.refresh_token,
        expiresAt: authResult.expiresIn * 1000 + new Date().getTime()
      })
    );
  }
}

export const auth = new Auth();
export const AuthContext = createContext(auth);

// Add auth to component
export const HOCAuthContext = (Component) =>
  (props) => {
    return (
      <AuthContext.Consumer>
        {auth => {
          return <Component {...props} auth={auth} />
        }}
      </AuthContext.Consumer>
    );
  }
