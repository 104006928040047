export const storeName = "SOCIAL_LAB";

//Add any Action Names here (In SCREAMING_SNAKE case) and you will magically be able to utilise selectors, and actions for them
export const asyncActionsNames = [
  "ADS",
  "AD_CREATIVES",
  "AD_SETS",
  "CAMPAIGNS",
  "AD_ACCOUNTS",
  "QUEUE_STATUS",
  "AD_ACCOUNTS_REPORTS",
  "DUPLICATE_ADS",
  "CREATIVE_REPORT",
  "OVERVIEW_REPORT",
  "OVERVIEW_REPORT_STEP",
  "UPDATE_AD_STATUS",
];
