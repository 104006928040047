import { storeName } from "../../asyncConfig";

/**
 * This 'reducer' will magically add the payload of a 'SUCCESS' type action, to the state (on a key matching the async action name)
 *
 * A Selector will be available to retrieve it
 */
//eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  const requestSuccessActionType = action.type.match(
    /(.*)\.REQUEST\.(.*)\.SUCCESS/
  );

  if (requestSuccessActionType && requestSuccessActionType[1] === storeName) {
    return {
      ...state,
      requestData: {
        ...state.requestData,
        [requestSuccessActionType[2]]: action.payload,
      },
    };
  }

  const requestResetActionType = action.type.match(
    /(.*)\.REQUEST\.(.*)\.RESET/
  );

  if (requestResetActionType && requestResetActionType[1] === storeName) {
    return {
      ...state,
      requestData: {
        ...state.requestData,
        [requestResetActionType[2]]: [],
      },
    };
  }

  return state;
};
